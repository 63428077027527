/* eslint-disable camelcase */
import React from 'react';
import ReactPlayer from 'react-player';
import { graphql } from 'gatsby';
import { range } from 'lodash';

import envel from '../images/simple/envel.png';
import simple from '../images/simple/simple.png';
import bankofamerica from '../images/simple/bankofamerica.png';
import betterment from '../images/simple/betterment.png';
import chime from '../images/simple/chime.png';
import m1finance from '../images/simple/m1finance.png';
import onefinance from '../images/simple/onefinance.png';
import qapital from '../images/simple/qapital.png';
import sofi from '../images/simple/sofi.png';
import varo from '../images/simple/varo.png';
import chase from '../images/simple/chase.png';
import demo from '../images/simple/demo_video.mp4';
import custom from '../images/simple/homepage.png';
import link_card from '../images/simple/link_card.png';
import link_bank from '../images/simple/envelopes.png';
import send from '../images/simple/send.png';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import SignUp from '../components/website/SignUp';
import {
	Header,
	SimpleSingupContainer,
	DemoVideo,
	TableBody,
	TableRow,
	FeatureSection,
	FeatureScreenImg,
	FeatureTitle,
	FeatureBody,
	Wrapper,
	TableContainer,
	TableLogo,
	TableFooter,
} from '../styles';

const pics = [
	{ name: 'simple', pic: simple },
	{ name: 'bankofamerica', pic: bankofamerica },
	{ name: 'betterment', pic: betterment },
	{ name: 'chime', pic: chime },
	{ name: 'm1finance', pic: m1finance },
	{ name: 'onefinance', pic: onefinance },
	{ name: 'qapital', pic: qapital },
	{ name: 'sofi', pic: sofi },
	{ name: 'varo', pic: varo },
	{ name: 'chase', pic: chase },
];

/**
 * Single job view (/:slug)
 *
 * This file renders a single job and loads all the content.
 *
 */
const Features = ({ data }) => {
	const banks = data.allGoogleFeatureSheet.edges;
	const enemyBank = banks.filter(({ node: { bank } }) => bank !== 'Envel' && bank !== 'Features')[0].node;
	const envelBank = banks.filter(({ node: { bank } }) => bank === 'Envel')[0].node;
	const features = banks.filter(({ node: { bank } }) => bank === 'Features')[0].node;
	const picture = pics.find(({ name }) => name.includes(enemyBank.bank.replace(/ /g, '').toLowerCase()));

	return (
		<Layout>
			<MetaData
				title={`How does Envel compare to ${enemyBank.bank}?`}
				description={`Envel is the next best alternative to ${enemyBank.bank}. View the comparisons and see why the decision to switching to Envel bank is a great choice.`}
				image="images/social/socialSimple.png"
			/>
			<Header center>How does Envel compare to {enemyBank.bank}?</Header>
			{/* <DemoVideo>
				<ReactPlayer
					id="demo_video"
					url={demo}
					playing
					muted
					playsinline
					width="100%"
					height="auto"
					controls={true}
				/>
			</DemoVideo> */}

			{/* <SimpleSingupContainer>
				<SignUp />
			</SimpleSingupContainer> */}

			<TableContainer>
				<TableRow alignCenter>
					<TableBody className="title">App Features</TableBody>
					<TableBody>
						<TableLogo src={envel} alt="Envel Logo" />
					</TableBody>
					<TableBody>
						<TableLogo src={picture.pic} alt={`${enemyBank.bank} Logo`} />
					</TableBody>
				</TableRow>
				{range(1, 30).map(i => (
					<TableRow key={i}>
						<TableBody>{features[`feature${i}`]}</TableBody>
						<TableBody>{envelBank[`feature${i}`]}</TableBody>
						<TableBody>{enemyBank[`feature${i}`]}</TableBody>
					</TableRow>
				))}
			</TableContainer>
			<TableFooter center>* Continuously listening to feedback and improving functionality</TableFooter>

			<SimpleSingupContainer>
				<SignUp />
			</SimpleSingupContainer>

			<FeatureSection alignCenter direction="row-reverse" className="first">
				<FeatureScreenImg src={custom} alt="custom" />
			</FeatureSection>

			<FeatureSection alignCenter>
				<Wrapper alignCenter>
					<FeatureTitle>Link your card to any of your envelopes</FeatureTitle>
					<FeatureBody>
						All you have to do is link your card before you make a purchase and the money will be taken out of the
						envelope of your choice
					</FeatureBody>
				</Wrapper>
				<Wrapper>
					<FeatureScreenImg src={link_card} alt="link_card" />
				</Wrapper>
			</FeatureSection>

			<FeatureSection alignCenter direction="row-reverse">
				<Wrapper alignCenter>
					<FeatureTitle>Link your other bank accounts</FeatureTitle>
					<FeatureBody>
						Login to your bank account via Envel and add your bank accounts to your Envel Wallet
					</FeatureBody>
				</Wrapper>
				<Wrapper>
					<FeatureScreenImg src={link_bank} alt="link_bank" />
				</Wrapper>
			</FeatureSection>

			<FeatureSection alignCenter className="last">
				<Wrapper alignCenter>
					<FeatureTitle>Send money to your friends in 2 seconds</FeatureTitle>
					<FeatureBody>Easy and fast transactions between Envel users</FeatureBody>
				</Wrapper>
				<Wrapper>
					<FeatureScreenImg src={send} alt="send" />
				</Wrapper>
			</FeatureSection>

			<SimpleSingupContainer>
				<SignUp switc={true} />
			</SimpleSingupContainer>
		</Layout>
	);
};

export default Features;

export const featureQuery = graphql`
	query($name: String!) {
		allGoogleFeatureSheet(filter: { bank: { in: ["Envel", "Features", $name] } }) {
			edges {
				node {
					bank
					feature10
					feature1
					feature11
					feature12
					feature13
					feature14
					feature15
					feature16
					feature17
					feature18
					feature19
					feature2
					feature20
					feature21
					feature22
					feature23
					feature24
					feature25
					feature26
					feature27
					feature28
					feature29
					feature3
					feature30
					feature4
					feature5
					feature6
					feature8
					feature7
					feature9
				}
			}
		}
	}
`;
